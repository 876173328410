import { useState } from "react";
import "./IndexPage.css";
import Terminal from "../components/terminal";

function IndexPage({ expand }: { expand?: string }) {
  const [beforeInteraction, setBeforeInteraction] = useState(false);
  if (beforeInteraction) {
  }

  return (
    <>
      <Terminal
        prompt="/dev/agents"
        onInteraction={() => setBeforeInteraction(false)}
        expand={expand}
      />
      <div className="circle-container">
        <div className="circle" id="circle1"></div>
        <div className="circle" id="circle2"></div>
        <div className="circle" id="circle3"></div>
      </div>
    </>
  );
}

export default IndexPage;
