import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

console.log(`

+------------------------------------------------------------------------------+
|                                                                              |
|                                                                              |
|\x1B[31;1m       /\\      ___              /\\                              __            \x1B[m|
|\x1B[31;1m      / /  ___/  /______  __   / / _____    ____   ____   _____/  |_ ______   \x1B[m|
|\x1B[31;1m     / /  / __  / __ \\  \\/ /  / /  \\__  \\  / ___\\_/ __ \\ /    \\   __/  ___/   \x1B[m|
|\x1B[31;1m    / /  / /_/ /  ___/\\   /  / /    / __ \\/ /_/  \\  ___/|   |  |  | \\___ \\    \x1B[m|
|\x1B[31;1m   / /   \\____/ \\______\\_/  / /    (______\\___  / \\_____|___|__|__|/______\\   \x1B[m|
|\x1B[31;1m   \\/                       \\/           /_____/                              \x1B[m|
|                                                                              |
|                                                                              |
|      Thank you for poking at the source - you would love it here!            |
|      Drop us a line: jobs@sdsa.ai                                            |
|                                                                              |
+------------------------------------------------------------------------------+

  
  `);