import { useParams } from "react-router-dom";
import { useEffect } from "react";

const PERSON_MAP: { readonly [key: string]: string } = {
  dps: "https://calendar.app.google/crz51y81AyASgWhP8",
  nj: "https://calendar.app.google/xAyuU9b8ZW8577HP7",
  ficus: "https://calendar.app.google/P2MSZ9QGuzuiGA9HA",
};

function MeetPage() {
  const { person } = useParams<{ person: string }>();

  useEffect(() => {
    if (person && PERSON_MAP[person]) {
      window.location.href = PERSON_MAP[person];
    } else {
      window.location.href = "https://sdsa.ai/";
    }
  }, [person]);

  return <div />;
}

export default MeetPage;
