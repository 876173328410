import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import IndexPage from "./pages/IndexPage";
import SupportPage from "./pages/SupportPage";
import MeetPage from "./pages/MeetPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/about" element={<IndexPage expand="about" />} />
        <Route path="/jobs" element={<IndexPage expand="jobs" />} />
        <Route path="/who" element={<IndexPage expand="who" />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/meet/:person" element={<MeetPage />} />
      </Routes>
    </Router>
  );
}

export default App;
