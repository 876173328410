import { ReactComponent as Glyph } from "./geist.svg";
import "./geist.css";

interface GeistComponentProps {
  mode: string;
  onClick?: () => void;
}
const GeistComponent: React.FC<GeistComponentProps> = ({
  mode,
  onClick,
}) => {

  return (
    <Glyph id="geist" className={mode} onClick={onClick}/>
  );
};

export default GeistComponent;
